export const REGULAR_DICTIONARY: string =
  'eddi://ai.labs.parser.dictionaries.regular';
export const DICTIONARY: string = 'eddi://ai.labs.parser.dictionaries';
export const CORRECTION: string = 'eddi://ai.labs.parser.corrections';
export const PARSER: string = 'eddi://ai.labs.parser';
export const BEHAVIOR: string = 'eddi://ai.labs.behavior';
export const OUTPUT: string = 'eddi://ai.labs.output';
export const HTTPCALLS: string = 'eddi://ai.labs.httpcalls';
export const GITCALLS: string = 'eddi://ai.labs.gitcalls';
export const PROPERTYSETTER: string = 'eddi://ai.labs.property';
export const CONVERSATION: string = 'eddi://ai.labs.conversation';

export const PACKAGE: string = 'eddi://ai.labs.package';
export const BOT: string = 'eddi://ai.labs.bot';

export const REGULAR_DICTIONARY_PATH: string =
  '/regulardictionarystore/regulardictionaries';
export const BEHAVIOR_PATH: string = '/behaviorstore/behaviorsets';
export const OUTPUT_PATH: string = '/outputstore/outputsets';
export const PACKAGE_PATH: string = '/packagestore/packages';
export const BOT_PATH: string = '/botstore/bots';
export const HTTPCALLS_PATH: string = '/httpcallsstore/httpcalls';
export const GITCALLS_PATH: string = '/gitcallsstore/gitcalls';
export const PROPERTYSETTER_PATH: string =
  '/propertysetterstore/propertysetters';
export const CONVERSATION_PATH: string = '/conversationstore/conversations';
