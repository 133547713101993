import { makeStyles } from '@material-ui/core/styles';
import {
  DARK_BLUE_BORDER,
  WHITE_COLOR,
  GREY_BORDER,
  LIGHT_GREY_COLOR2,
  LIGHT_BLUE_COLOR3,
  MEDIUM_FONT,
  MEDIUM_FONT3,
  SMALL_FONT,
  YELLOW_COLOR,
} from '../../../../styles/DefaultStylingProperties';

const useStyles = makeStyles({
  conversation: {
    '&:hover': {
      backgroundColor: LIGHT_BLUE_COLOR3,
    },
    display: 'flex',
    borderBottom: GREY_BORDER,
    paddingBottom: '2px',
    paddingTop: '6px',
    cursor: 'pointer',
    width: '100%',
  },
  conversationName: {
    color: WHITE_COLOR,
    fontSize: MEDIUM_FONT3,
    display: 'block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  conversationVersion: {
    backgroundColor: YELLOW_COLOR,
    borderRadius: '100px',
    color: WHITE_COLOR,
    fontSize: MEDIUM_FONT,
    height: '21px',
    marginLeft: '15px',
    padding: '2px 10px',
    textAlign: 'center',
    width: 'fit-content',
    lineHeight: '17px',
    marginTop: '5px',
  },
  nameAndVersion: {
    display: 'flex',
    maxWidth: '300px',
  },
  environment: {
    color: WHITE_COLOR,
    fontSize: SMALL_FONT,
    height: '20px',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    minWidth: '80px',
    marginRight: '15px',
  },
  conversationState: {
    color: WHITE_COLOR,
    fontSize: SMALL_FONT,
    height: '20px',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    textAlign: 'left',
    minWidth: '110px',
    marginRight: '20px',
  },
  conversationStepSizeContainer: {
    minWidth: '80px',
    marginLeft: 'auto',
  },
  conversationStepSize: {
    color: WHITE_COLOR,
    fontSize: MEDIUM_FONT,
    borderRadius: '100px',
    height: '21px',
    padding: '2px 5px',
    border: DARK_BLUE_BORDER,
    lineHeight: '16px',
    width: 'fit-content',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '3px',
  },
  createdOn: {
    color: WHITE_COLOR,
    textAlign: 'right',
    fontSize: SMALL_FONT,
    height: '20px',
    marginTop: '5px',
    whiteSpace: 'nowrap',
  },
  lastModifiedOn: {
    color: WHITE_COLOR,
    textAlign: 'left',
    fontSize: SMALL_FONT,
    height: '20px',
    marginTop: '5px',
    whiteSpace: 'nowrap',
    minWidth: '120px',
  },
  centerFlex: {},
});

export default useStyles;
