import * as React from 'react';
import { compose, pure, setDisplayName } from 'recompose';
import { BOT_VIEW } from '../../constants/paths';
import eddiApiActionDispatchers from '../../actions/EddiApiActionDispatchers';
import modalActionDispatchers from '../../actions/ModalActionDispatchers';
import { historyPush } from '../../history';
import WhiteButton from '../Assets/Buttons/WhiteButton';
import { createNewBot } from '../utils/AxiosFunctions';
import useStyles from './ModalComponent.styles';
import './ModalComponent.styles.scss';

const CreateBotModal = () => {
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  const classes = useStyles();

  const createBot = async () => {
    const botId = await createNewBot(name, description);
    eddiApiActionDispatchers.createNewBotAction(botId);
    historyPush(`${BOT_VIEW.replace(':id', botId)}/`);
    modalActionDispatchers.closeModal();
  };

  return (
    <div>
      <div className={classes.modalHeader}>
        <div className={classes.modalTopHeader}>
          <h2 className={classes.botHeaderText}>{'Create new bot'}</h2>
          <WhiteButton
            disabled={!name}
            text={'Create bot'}
            onClick={createBot}
            classes={{ button: classes.createBotButton }}
          />
        </div>
      </div>
      <div className={classes.content}>
        <div className={classes.botText}>
          {'Give the bot a name'}
          <div className={classes.inputBoxContent}>
            <textarea
              name={'name'}
              defaultValue={''}
              className={classes.inputBoxName}
              placeholder={'Give the bot a name..'}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.botText}>
          {'Give the bot a short description'}
          <div className={classes.inputBoxContent}>
            <textarea
              name={'description'}
              className={classes.inputBox}
              defaultValue={''}
              placeholder={'Give the bot a short description..'}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const ComposedCreateBotModal = compose(
  pure,
  setDisplayName('Modal'),
)(CreateBotModal);

export default ComposedCreateBotModal;
