// -------------- COLORS ------------------------------
export const BLACK_COLOR: string = '#000';
export const WHITE_COLOR: string = '#FFF';
export const RED_COLOR: string = '#FF5976';
export const DARK_RED_COLOR: string = '#ff2448';
export const GREEN_COLOR: string = '#4BCA81';
export const DARK_GREEN_COLOR: string = '#32ae68';
export const YELLOW_COLOR: string = '#e8ad16';
export const LIGHT_YELLOW_COLOR: string = '#f6dea2';
export const LIGHTER_YELLOW_COLOR: string = '#faeed1';
export const DARK_YELLOW_COLOR: string = '#FFDC00';
export const ORANGE_COLOR: string = '#FF851B';

export const BLUE_COLOR: string = '#007d9c';
export const BLUE_COLOR_TRANSPARENT: string = '#26a3c280';
export const BLUE_COLOR2: string = '#007d9c';
export const DARK_BLUE_COLOR: string = '#16325C';
export const LIGHT_BLUE_COLOR: string = '#26a3c2';
export const LIGHT_BLUE_COLOR2: string = '#d6e9ff';
export const LIGHT_BLUE_COLOR3: string = '#cce5eb4d';

export const GREY_COLOR: string = '#808080';
export const GREY_COLOR2: string = '#A8B7C7';
export const GREY_COLOR3: string = '#7194c9';
export const DARK_GREY_COLOR: string = '#262626';
export const LIGHT_GREY_COLOR: string = '#D8DDE6';
export const LIGHT_GREY_COLOR2: string = '#cce5eb';
export const LIGHT_GREY_COLOR3: string = '#b3b3b3';

// --------------- BORDERS ----------------------------
export const RED_BORDER: string = `1px solid ${RED_COLOR}`;
export const GREEN_BORDER: string = `1px solid ${GREEN_COLOR}`;
export const YELLOW_BORDER: string = `1px solid ${YELLOW_COLOR}`;
export const BLUE_BORDER: string = `1px solid ${BLUE_COLOR}`;
export const DARK_BLUE_BORDER: string = `1px solid ${DARK_BLUE_COLOR}`;
export const LIGHT_BLUE_BORDER: string = `1px solid ${LIGHT_BLUE_COLOR}`;
export const GREY_BORDER: string = `1px solid ${GREY_COLOR}`;
export const DARK_GREY_BORDER: string = `1px solid ${DARK_GREY_COLOR}`;
export const LIGHT_GREY_BORDER: string = `1px solid ${LIGHT_GREY_COLOR}`;

// --------------- FONT SIZES -------------------------
export const SMALL_FONT: string = '12px';
export const SMALL_FONT2: string = '14px';
export const MEDIUM_FONT: string = '16px';
export const MEDIUM_FONT2: string = '18px';
export const MEDIUM_FONT3: string = '20px';
export const LARGE_FONT: string = '24px';
export const LARGE_FONT2: string = '25px';
export const LARGE_FONT3: string = '28px';