export const FETCH_BOTS: string = 'FETCH_BOTS';
export const FETCH_BOTS_SUCCESS: string = 'FETCH_BOTS_SUCCESS';
export const FETCH_BOTS_FAILED: string = 'FETCH_BOTS_FAILED';

export const FETCH_BOT: string = 'FETCH_BOT';
export const FETCH_BOT_SUCCESS: string = 'FETCH_BOT_SUCCESS';
export const FETCH_BOT_FAILED: string = 'FETCH_BOT_FAILED';

export const FETCH_CURRENT_BOT: string = 'FETCH_CURRENT_BOT';

export const FETCH_BOTDATA: string = 'FETCH_BOTDATA';
export const FETCH_BOTDATA_SUCCESS: string = 'FETCH_BOTDATA_SUCCESS';
export const FETCH_BOTDATA_FAILED: string = 'FETCH_BOTDATA_FAILED';

export const FETCH_BOT_DETAILS: string = 'FETCH_BOT_DETAILS';
export const FETCH_BOT_DETAILS_SUCCESS: string = 'FETCH_BOT_DETAILS_SUCCESS';
export const FETCH_BOT_DETAILS_FAILED: string = 'FETCH_BOT_DETAILS_FAILED';

export const FETCH_PACKAGE: string = 'FETCH_PACKAGE';
export const FETCH_PACKAGE_SUCCESS: string = 'FETCH_PACKAGE_SUCCESS';
export const FETCH_PACKAGE_FAILED: string = 'FETCH_PACKAGE_FAILED';

export const FETCH_CURRENT_PACKAGE: string = 'FETCH_CURRENT_PACKAGE';

export const FETCH_PACKAGES: string = 'FETCH_PACKAGES';
export const FETCH_PACKAGES_SUCCESS: string = 'FETCH_PACKAGES_SUCCESS';
export const FETCH_PACKAGES_FAILED: string = 'FETCH_PACKAGES_FAILED';

export const FETCH_DEFAULT_PLUGIN_TYPES: string = 'FETCH_DEFAULT_PLUGIN_TYPES';
export const FETCH_DEFAULT_PLUGIN_TYPES_SUCCESS: string =
  'FETCH_DEFAULT_PLUGIN_TYPES_SUCCESS';
export const FETCH_DEFAULT_PLUGIN_TYPES_FAILED: string =
  'FETCH_DEFAULT_PLUGIN_TYPES_FAILED';

export const FETCH_PLUGINS: string = 'FETCH_PLUGINS';
export const FETCH_PLUGINS_SUCCESS: string = 'FETCH_PLUGINS_SUCCESS';
export const FETCH_PLUGINS_FAILED: string = 'FETCH_PLUGINS_FAILED';

export const FETCH_PLUGIN: string = 'FETCH_PLUGIN';
export const FETCH_PLUGIN_SUCCESS: string = 'FETCH_PLUGIN_SUCCESS';
export const FETCH_PLUGIN_FAILED: string = 'FETCH_PLUGIN_FAILED';

export const UPDATE_BOT: string = 'UPDATE_BOT';
export const UPDATE_BOT_SUCCESS: string = 'UPDATE_BOT_SUCCESS';
export const UPDATE_BOT_FAILED: string = 'UPDATE_BOT_FAILED';

export const UPDATE_BOT_PACKAGES: string = 'UPDATE_BOT_PACKAGES';
export const UPDATE_BOT_PACKAGES_SUCCESS: string =
  'UPDATE_BOT_PACKAGES_SUCCESS';
export const UPDATE_BOT_PACKAGES_FAILED: string = 'UPDATE_BOT_PACKAGES_FAILED';

export const UPDATE_PACKAGE: string = 'UPDATE_PACKAGE';
export const UPDATE_PACKAGE_SUCCESS: string = 'UPDATE_PACKAGE_SUCCESS';
export const UPDATE_PACKAGE_FAILED: string = 'UPDATE_PACKAGE_FAILED';

export const ADD_AVAILABLE_UPDATE_FOR_PACKAGE: string =
  'ADD_AVAILABLE_UPDATE_FOR_PACKAGE';

export const FETCH_PACKAGEDATA: string = 'FETCH_PACKAGEDATA';
export const FETCH_PACKAGEDATA_SUCCESS: string = 'FETCH_PACKAGEDATA_SUCCESS';
export const FETCH_PACKAGEDATA_FAILED: string = 'FETCH_PACKAGEDATA_FAILED';

export const UPDATE_DESCRIPTOR: string = 'UPDATE_DESCRIPTOR';
export const UPDATE_DESCRIPTOR_SUCCESS: string = 'UPDATE_DESCRIPTOR_SUCCESS';
export const UPDATE_DESCRIPTOR_FAILED: string = 'UPDATE_DESCRIPTOR_FAILED';

export const FETCH_BOTS_USING_PACKAGE: string = 'FETCH_BOTS_USING_PACKAGE';
export const FETCH_BOTS_USING_PACKAGE_SUCCESS: string =
  'FETCH_BOTS_USING_PACKAGE_SUCCESS';
export const FETCH_BOTS_USING_PACKAGE_FAILED: string =
  'FETCH_BOTS_USING_PACKAGE_FAILED';

export const FETCH_PACKAGES_USING_PLUGIN: string =
  'FETCH_PACKAGES_USING_PLUGIN';
export const FETCH_PACKAGES_USING_PLUGIN_SUCCESS: string =
  'FETCH_PACKAGES_USING_PLUGIN_SUCCESS';
export const FETCH_PACKAGES_USING_PLUGIN_FAILED: string =
  'FETCH_PACKAGES_USING_PLUGIN_FAILED';

export const UPDATE_JSON_DATA: string = 'UPDATE_JSON_DATA';
export const UPDATE_JSON_DATA_FAILED: string = 'UPDATE_JSON_DATA_FAILED';

export const UPDATE_PLUGIN_SUCCESS: string = 'UPDATE_PLUGIN_SUCCESS';

export const CREATE_NEW_CONFIG: string = 'CREATE_NEW_CONFIG';
export const CREATE_NEW_CONFIG_FAILED: string = 'CREATE_NEW_CONFIG_FAILED';

export const CREATE_NEW_PLUGIN_SUCCESS: string = 'CREATE_NEW_PLUGIN_SUCCESS';

export const CREATE_NEW_BOT: string = 'CREATE_NEW_BOT';
export const CREATE_NEW_BOT_FAILED: string = 'CREATE_NEW_BOT_FAILED';
export const CREATE_NEW_BOT_SUCCESS: string = 'CREATE_NEW_BOT_SUCCESS';

export const CREATE_NEW_PACKAGE: string = 'CREATE_NEW_PACKAGE';
export const CREATE_NEW_PACKAGE_FAILED: string = 'CREATE_NEW_PACKAGE_FAILED';
export const CREATE_NEW_PACKAGE_SUCCESS: string = 'CREATE_NEW_PACKAGE_SUCCESS';

export const UPDATE_PACKAGES: string = 'UPDATE_PACKAGES';
export const UPDATE_PACKAGES_SUCCESS: string = 'UPDATE_PACKAGES_SUCCESS';
export const UPDATE_PACKAGES_FAILED: string = 'UPDATE_PACKAGES_FAILED';

export const UPDATE_BOTS: string = 'UPDATE_BOTS';
export const UPDATE_BOTS_SUCCESS: string = 'UPDATE_BOTS_SUCCESS';
export const UPDATE_BOTS_FAILED: string = 'UPDATE_BOTS_FAILED';

export const DEPLOY_BOT: string = 'DEPLOY_BOT';
export const DEPLOY_BOT_SUCCESS = 'DEPLOY_BOT_SUCCESS';
export const DEPLOY_BOT_FAILED = 'DEPLOY_BOT_FAILED';

export const UNDEPLOY_BOT: string = 'UNDEPLOY_BOT';
export const UNDEPLOY_BOT_SUCCESS: string = 'UNDEPLOY_BOT_SUCCESS';
export const UNDEPLOY_BOT_FAILED: string = 'UNDEPLOY_BOT_FAILED';

export const FETCH_BOT_DEPLOYMENT_STATUS: string =
  'FETCH_BOT_DEPLOYMENT_STATUS';
export const FETCH_BOT_DEPLOYMENT_STATUS_SUCCESS: string =
  'FETCH_BOT_DEPLOYMENT_STATUS_SUCCESS';
export const FETCH_BOT_DEPLOYMENT_STATUS_FAILED: string =
  'FETCH_BOT_DEPLOYMENT_STATUS_FAILED';

export const ADD_NEW_PACKAGE_TO_BOTS: string = 'ADD_NEW_PACKAGE_TO_BOTS';
export const ADD_NEW_PACKAGE_TO_BOTS_SUCCESS: string =
  'ADD_NEW_PACKAGE_TO_BOTS_SUCCESS';
export const ADD_NEW_PACKAGE_TO_BOTS_FAILED: string =
  'ADD_NEW_PACKAGE_TO_BOTS_FAILED';

export const FETCH_JSON_SCHEMA: string = 'FETCH_JSON_SCHEMA';
export const FETCH_JSON_SCHEMA_FAILED: string = 'FETCH_JSON_SCHEMA_FAILED';
export const FETCH_BOT_JSON_SCHEMA_SUCCESS: string =
  'FETCH_BOT_JSON_SCHEMA_SUCCESS';
export const FETCH_PACKAGE_JSON_SCHEMA_SUCCESS: string =
  'FETCH_PACKAGE_JSON_SCHEMA_SUCCESS';
export const FETCH_PLUGIN_JSON_SCHEMA_SUCCESS: string =
  'FETCH_PLUGIN_JSON_SCHEMA_SUCCESS';

export const DUPLICATE: string = 'DUPLICATE';
export const DUPLICATE_SUCCESS: string = 'DUPLICATE_SUCCESS';
export const DUPLICATE_FAILED: string = 'DUPLICATE_FAILED';

export const FETCH_CONVERSATIONS: string = 'FETCH_CONVERSATIONS';
export const FETCH_CONVERSATIONS_SUCCESS: string =
  'FETCH_CONVERSATIONS_SUCCESS';
export const FETCH_CONVERSATIONS_FAILED: string = 'FETCH_CONVERSATIONS_FAILED';

export const FETCH_CONVERSATION: string = 'FETCH_CONVERSATION';
export const FETCH_CONVERSATION_SUCCESS: string = 'FETCH_CONVERSATION_SUCCESS';
export const FETCH_CONVERSATION_FAILED: string = 'FETCH_CONVERSATION_FAILED';

export const END_CONVERSATION: string = 'END_CONVERSATION';
export const END_CONVERSATION_SUCCESS: string = 'END_CONVERSATION_SUCCESS';
export const END_CONVERSATION_FAILED: string = 'END_CONVERSATION_FAILED';

export const DEPLOY_EXAMPLE_BOTS: string = 'DEPLOY_EXAMPLE_BOTS';
export const DEPLOY_EXAMPLE_BOTS_SUCCESS: string =
  'DEPLOY_EXAMPLE_BOTS_SUCCESS';
export const DEPLOY_EXAMPLE_BOTS_FAILED: string = 'DEPLOY_EXAMPLE_BOTS_FAILED';
export const FETCH_BOT_LOGS: string = 'FETCH_BOT_LOGS';
export const FETCH_BOT_LOGS_SUCCESS: string = 'FETCH_BOT_LOGS_SUCCESS';
export const FETCH_BOT_LOGS_FAILED: string = 'FETCH_BOT_LOGS_FAILED';
export const RESET_BOT_LOGS: string = 'RESET_BOT_LOGS';
export const EDIT_PLUGIN_DATA: string = 'EDIT_PLUGIN_DATA';
export const CLEAR_EDITED_PLUGIN_DATA: string = 'CLEAR_EDITED_PLUGIN_DATA';
export const MASS_UPDATE_JSON_DATA: string = 'MASS_UPDATE_JSON_DATA';
export const UPDATE_EXTENSIONS_ORDER: string = 'UPDATE_EXTENSIONS_ORDER';
export const UPDATE_EXTENSIONS_ORDER_SUCCESS: string =
  'UPDATE_EXTENSIONS_ORDER_SUCCESS';
export const UPDATE_EXTENSIONS_ORDER_FAILED: string =
  'UPDATE_EXTENSIONS_ORDER_FAILED';
